import React, { lazy, Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import axios from "axios";
import { API_BACKEND } from "../../config";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";

const Root = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  @media (min-width: 1024px) {
    flex-direction: row;
    height: 50px;
    padding-bottom: 0;
  }
`;

const Title = styled.h2`
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-size: 1.15rem;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
  }
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 1024px) {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ActionButton = styled.button`
  background: transparent;
  border: none;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
`;

const ConfigSection = () => {
  const { id } = useParams();

  const intl = useIntl();
  let navigate = useNavigate();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const [data, setData] = React.useState(null);

  const templateRef = React.useRef();

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeData = (name, value) => {
    setData({
      ...data,
      data: {
        ...data.data,
        [name]: value,
      },
    });
  };

  const back = () => {
    navigate(-1);
  };

  const submit = (e) => {
    e.preventDefault();
    templateRef.current.handleAction();
  };

  const Template = React.lazy(async () => {
    return import(`../templatesSections/${data?.type}`);
  });

  const getData = () => {
    axios
      .get(`${API_BACKEND}/sections/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Root>
      <Header>
        <Title>{`${intl.formatMessage({ id: "config section" })} (${
          data ? intl.formatMessage({ id: data.type }) : null
        })`}</Title>
        <Actions>
          <ActionButton onClick={submit}>{translate("apply")}</ActionButton>
          <ActionButton type="button" onClick={back}>
            {translate("cancel")}
          </ActionButton>
        </Actions>
      </Header>
      {data && (
        <Container>
          <Suspense fallback={<div>Loading...</div>}>
            <Template
              ref={templateRef}
              id={id}
              userId={userData.id}
              editData={data.data}
            />
          </Suspense>
        </Container>
      )}
    </Root>
  );
};

export default ConfigSection;
